
#registerForm form{
    width: 30%;
    margin: auto;
    padding-top: 40px;
}
#registerForm form h2{
    color: var(--background);
}
#registerForm input{
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    margin-bottom: 2px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
#registerForm ul{
    padding: 0;
    margin: 0;
}
#registerForm ul li{
    list-style: none;
    color: grey;
    font-size: 0.9rem;
}
#registerForm input[type="submit"]{
    background: var(--background);
    border-radius: 5px;
    padding: 5px;
    color: white;
    border: none;
    transition: 1s;
}
#registerForm input[type="submit"]:hover{
    background: var(--btnHover);
}
#registerForm select{
    width: 50%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    margin-bottom: 2px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.links p{
    font-size: 0.875rem;
    padding: 10px 0;
}
#registerForm span{
    color: rgb(247, 2, 2);
    font-size: 14px;
    margin: 0;
    padding: 0;
}
@media only screen and (max-width: 998px){
    #registerForm form{
        width: 90%;
        margin: auto;
        padding-top: 40px;
    }
}