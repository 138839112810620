.otp{
    max-width: 400px;
    margin: auto; /* Center horizontally */
    margin-top: 100px; /* Adjust as needed for vertical centering */
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}
.otp h2{
    text-align: center;
    color: #333;
}
.otp .form-group {
    margin-bottom: 20px;
}
.otp label {
    display: block;
    margin-bottom: 5px;
    color: #666;
}
.otp input[type="text"] {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
}
.otp button {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
}
.otp button:hover {
    background-color: #0056b3;
}
.otp .error-message {
    color: #ff0000;
    text-align: center;
    margin-top: 10px;
}
