/*dashboard area */
.dashboard{
    display: flex;
    flex-wrap: wrap;
}
.dashboard .div:first-child a{
    display: block;
    padding: 13px;
    text-decoration: none;
    color: white;
    transition: 1s;
    font-size: 0.875rem;
}
.dashboard .div:first-child a:hover{
    background: whitesmoke;
    color: darkcyan;
    border-bottom: 2px solid red;
}
.dashboard .div:first-child{
    flex: 20%;
    background: darkcyan;
    height: 100vh;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
}
.dashboard .div:last-child{
    flex: 80%;
}
.dashboard .div:first-child img{
    width: 150px;
    height: 150px;
    object-fit: cover;
    display: block;
    margin: auto;
    border-radius: 100%;
    border: 5px solid rgb(125, 186, 186);
}
.dashboard .div figcaption{
    text-align: center;
    color: white;
    padding: 10px 0 5px 0;
    font-size: 1.2rem;
}
.dashboard .div:first-child small{
    display: block;
    text-align: center;
    color: white;
    font-size: 0.775rem;
}
.dashboard .div:first-child hr{
    background-color: rgb(185, 185, 185);
    border: 0;
    height: 1px;
    margin: 10px 0 0 0;
}
.dashboard .div:first-child div{
    margin-bottom: 20%;
}
.active{
    background: red;
    border-bottom: 2px solid white;
}

.dashboard .div:last-child .system_name{
    padding: 10px;
    text-align: center;
    background: rgb(232, 224, 224);
}
.dashboard .div:last-child .system_name p{
    font-size: 1.2rem;
    font-weight: 700;
    color: darkcyan;
}
.quickStats{
    display: flex;
    gap: 2%;
    text-align: center;
    padding: 30px 2.5%    ;
}
.quickStats div{
    flex: 30%;
    background: rgb(13, 96, 123);
    height: 15vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    color: white;
    position: relative;
}
.quickStats div:nth-child(2){
    background: teal;
}
.quickStats div:nth-child(3){
    background: tomato;
}
.quickStats p{
    padding: 5px 0;
    font-size: 1rem;
}
.quickStats i{
    font-size: 1.8rem;
}
.quickStats span{
    margin-top: 10px;
    position: absolute;
    top: 10%;
    right: 10%;
    font-weight: 900;
    font-size: 1.5rem;
}
.payments{
    padding: 0 2.5%;
}
.sticky{
    position: sticky;
    top: 0;
}
.scrollable{
    height: 90vh;
    overflow-y: auto;
}