.statistics-dashboard {
    margin: 20px auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
}

.statistics-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    gap: 20px;
}

.statistic-item {
    background-color: #fff;
    padding: 10px;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
}

.statistic-item h4 {
    font-size: 18px;
    margin-bottom: 10px;
}

.statistic-item ul {
    list-style-type: none;
    padding: 0;
}

.statistic-item li {
    margin-bottom: 5px;
}

.statistic-item p {
    margin: 0;
    font-size: 16px;
    font-weight: bold;
}