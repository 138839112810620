
.otherActions button{
    display: block;
    padding: 10px;
    background-color: var(--backgroundLight);
    margin: 5px 0;
    border: none;
    border-left: 10px solid var(--background);
    border-radius: 5px;
    color: black;
    font-size: 14px;
    transition: .5s;
}
.otherActions button:hover{
    scale: 1.05;
}