table {
  border-collapse: collapse;
  width: 100%;
  margin-top: 10px;
}

table td,
th {
  border: 1px solid #ddd;
  padding: 8px;
  font-size: 14px;
}

table tr:nth-child(even) {
  background-color: #f2f2f2;
}

table tr:hover {
  background-color: #ddd;
}

table th {
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: left;
  background-color: #04AA6D;
  background-color: var(--btnBackground);
  color: white;
  font-weight: normal;
}

table i {
  cursor: pointer;
}

h3{
  font-size: 16px;
  padding: 8px;
  border-left: 5px solid var(--background);
  padding-left: 10px;
  margin-bottom: 10px;
}

.correct {
  color: green;
  font-weight: bold;
}

.incorrect {
  color: red;
  font-weight: bold;
}